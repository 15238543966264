import { Component } from "react";

const title = "Growth Roadmap";
const desc = "Entering The Penqueens Society means joining a family full of people who believe in the future of Cryptocurrencies and the Blockchain technology.";


let roadmapList = [
    {
        className: 'roadmap__thumb',
        parent: '10%',
        title: '1.Launch Initiated',
        desc: 'Our number one priority is keeping our NFT quality worthy of our artist’s name Each Bull goes through the same creative proces Preparations mustbe made to ensure the Penqueens Society collection lives up nto the standard of excellence as any art with a projects The launch date will be announced as soon as the project\'s quality and technical aspects are fully ready.',
    },
    {
        className: 'roadmap__thumb roadmap__thumb--bg-2',
        parent: '20%',
        title: '2. Staking',
        desc: 'Our number one priority is keeping our NFT quality worthy of our artist’s name Each Bull goes through the same creative proces Preparations mustbe made to ensure the Penqueens Society collection lives up nto the standard of excellence as any art with a projects The launch date will be announced as soon as the project\'s quality and technical aspects are fully ready.',
    },
    {
        className: 'roadmap__thumb roadmap__thumb--bg-3',
        parent: '40%',
        title: '3.Physical Merch & Live Events',
        desc: 'Our number one priority is keeping our NFT quality worthy of our artist’s name Each Bull goes through the same creative proces Preparations mustbe made to ensure the Penqueens Society collection lives up nto the standard of excellence as any art with a projects The launch date will be announced as soon as the project\'s quality and technical aspects are fully ready.',
    },
    {
        className: 'roadmap__thumb roadmap__thumb--bg-4',
        parent: '60%',
        title: '4.The Penqueens',
        desc: 'Our number one priority is keeping our NFT quality worthy of our artist’s name Each Bull goes through the same creative proces Preparations mustbe made to ensure the Penqueens Society collection lives up nto the standard of excellence as any art with a projects The launch date will be announced as soon as the project\'s quality and technical aspects are fully ready.',
    },
    {
        className: 'roadmap__thumb roadmap__thumb--bg-5',
        parent: '80%',
        title: '5.Metaverse',
        desc: 'Our number one priority is keeping our NFT quality worthy of our artist’s name Each Bull goes through the same creative proces Preparations mustbe made to ensure the Penqueens Society collection lives up nto the standard of excellence as any art with a projects The launch date will be announced as soon as the project\'s quality and technical aspects are fully ready.',
    },
]


class Roadmap extends Component {
    render() { 
        return (
            <section className="roadmap padding-top padding-bottom" id="roadmap">
                <div className="container">
                    <div className="section-header" data-aos="fade-up" data-aos-duration="1000">
                        <h2>{title}</h2>
                        <p>{desc}</p>
                    </div>
                    <div className="roadmap__wrapper">
                        <div className="row">
                            {roadmapList.map((val, i) => (
                                <div className="col-12" key={i}>
                                    <div className="roadmap__item" data-aos="fade-right" data-aos-duration="1000">
                                        <div className="roadmap__inner">
                                            <div className={val.className}>
                                                <span>{val.parent}</span>
                                            </div>
                                            <div className="roadmap__content">
                                                <h4>{val.title}</h4>
                                                <p>{val.desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Roadmap;