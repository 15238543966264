import { Component } from "react";

const title = "Meet The Team";
const desc = "Entering The Penqueens Society means joining an family full of people who believe in the future of Cryptocurrencies and the Blockchain technology.";

let teamList = [
    {
        imgUrl: 'assets/images/team/01.png',
        imgAlt: 'team Image',
        name: 'Somrat Islam',
        degi: 'UI/UX Designer',
        social: [
            {
                iconImgUrl: 'assets/images/team/discord.png',
                siteLink: '#',
            },
            {
                iconImgUrl: 'assets/images/team/insta.png',
                siteLink: '#',
            },
        ],
    },
    {
        imgUrl: 'assets/images/team/02.png',
        imgAlt: 'team Image',
        name: 'Rassel Hossain',
        degi: 'Front End Designer',
        social: [
            {
                iconImgUrl: 'assets/images/team/discord.png',
                siteLink: '#',
            },
            {
                iconImgUrl: 'assets/images/team/insta.png',
                siteLink: '#',
            },
        ],
    },
    {
        imgUrl: 'assets/images/team/03.png',
        imgAlt: 'team Image',
        name: 'Shajahan Sagor',
        degi: 'UI/UX Designer',
        social: [
            {
                iconImgUrl: 'assets/images/team/discord.png',
                siteLink: '#',
            },
            {
                iconImgUrl: 'assets/images/team/insta.png',
                siteLink: '#',
            },
        ],
    },
    {
        imgUrl: 'assets/images/team/04.png',
        imgAlt: 'team Image',
        name: 'Rajib Ahmed',
        degi: 'Web Designer',
        social: [
            {
                iconImgUrl: 'assets/images/team/discord.png',
                siteLink: '#',
            },
            {
                iconImgUrl: 'assets/images/team/insta.png',
                siteLink: '#',
            },
        ],
    },
]


class Team extends Component {
    render() { 
        return (
            <section className="team padding-top padding-bottom bg__theme-color" id="team">
                <div className="container">
                    <div className="section-header" data-aos="fade-up" data-aos-duration="1000">
                        <h2>{title}</h2>
                        <p>{desc}</p>
                    </div>
                    <div className="team__wrapper">
                        <div className="row g-4">
                            {teamList.map((val, i) => (
                                <div className="col-lg-3 col-sm-6" key={i}>
                                    <div className="team__item" data-aos="fade-up" data-aos-duration="500">
                                        <div className="team__inner">
                                            <div className="team__thumb">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="team__content">
                                                <h4><a href="#">{val.name}</a></h4>
                                                <p>{val.degi}</p>
                                                <ul className="social-list g-10 d-flex justify-content-center">
                                                    {val.social.map((val, i) => (
                                                        <li className="social-item" key={i}>
                                                            <a href={val.siteLink}><img src={`${val.iconImgUrl}`} alt={`${val.imgAlt}`} /></a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Team;