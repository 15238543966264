import { Component } from "react";



class AboutTwo extends Component {
    render() { 
        return (
            <section className="about padding-top padding-bottom bg__theme-color">
                <div className="container">
                    <div className="about__wrapper">
                        <div className="row g-5 align-items-center">
                            <div className="col-lg-6">
                                <div className="about__content" data-aos="fade-right" data-aos-duration="1000">
                                    <h2>Art x Penqueens</h2>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vo luptas de leniti voluptatibus officia! Ipsum porro repellendu quis fuga illooxe in officia consectetur adipisicing elitRe.</p>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vo luptas de leniti voluptatibus officia! Ipsum porro repellendu quis fuga illooxe in officia consectetur adipisicing elitRe.</p>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vo luptas de leniti voluptatibus officia! Ipsum porro repellendu quis fuga illooxe in officia consectetur adipisicing elitRe.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about__thumb" data-aos="zoom-in" data-aos-duration="1000">
                                    <img src="assets/images/about/01.png" alt="Art Image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default AboutTwo;